import {createTheme, responsiveFontSizes} from "@mui/material/styles";

const theme = createTheme({
    palette: {
        background: {
            default: '#FCF8F2'
        },
        primary: {
            main: '#67a3c7',
            light: '#adcbe1'
        },
        secondary: {
            main: '#b39877',
            dark: '#926c3c',
            contrastText: '#eeeeee'
        },
        info: {
            main: '#fff',
            dark: '#eee',
            light: '#1A363A',
            contrastText: '#1A363A',
        },
        divider: '#C4D3C7',
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 28,
                    fontWeight: "bold"
                },
            },
        },
        MuiTextField: {
            defaultProps: {
                variant: 'outlined'
            }
        },
        MuiFilledInput: {
            styleOverrides: {
                root: {
                    borderColor: '#CDA559',
                    backgroundColor: 'white',
                }
            },
        }
    },
    typography: {
        allVariants: {
            color: '#333333'
        },
        body1: {
            fontSize: '1.5rem',
        },
        body2: {
            fontSize: '1rem'
        },
        h2: {
            textTransform: 'uppercase',
            fontWeight: 'bold'
        },
        h3: {
            textTransform: 'uppercase',
            fontWeight: 'bold'
        },
        h4: {
            textTransform: 'uppercase',
            fontWeight: 'bold'
        },
        h5: {
            textTransform: 'uppercase',
            fontWeight: 'bold'
        },
        subtitle2: {
            fontSize: '1rem'
        },
        fontFamily: [
            'Josefin Sans',
            'Arial',
        ].join(','),
    },

});

export default responsiveFontSizes(theme);
