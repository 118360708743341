import * as React from "react"
import wrapWithProvider from './src/wrap-with-provider';
import Layout from "./src/components/layout";

import './src/styles/global.css'

export const wrapRootElement = wrapWithProvider;

export const wrapPageElement = ({ element, props }) => {
    // props provide same data to Layout as Page element will get
    // including location, data, etc - you don't need to pass it
    return <Layout {...props}>{element}</Layout>
}
