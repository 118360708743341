import React from 'react'
import Toolbar from "@mui/material/Toolbar";
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import MenuIcon from '@mui/icons-material/Menu';
import {Button, Drawer, IconButton} from "@mui/material";
import {StaticImage} from "gatsby-plugin-image";
import CssBaseline from "@mui/material/CssBaseline";
import {Link} from "gatsby";
import {Close} from "@mui/icons-material";

interface MenuItemProps {
    url: string
    title: string
    important?: boolean
}

const Header = () => {
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const MenuLink = ({url, title, important = true}: MenuItemProps) => {
        return (
            <Button
                component={Link}
                to={url}
                key={title}
                onClick={handleDrawerToggle}
            >
                <Typography
                    textAlign="center"
                    fontWeight={important ? 'bold' : 'normal'}
                    variant={'body2'}
                >
                    {title}
                </Typography>
            </Button>
        )
    }

    const menu = [
        <MenuLink key={'locatie'} url={'/locatie'} title={'Locatie'}/>,
        <MenuLink key={'situatie'} url={'/situatie'} title={'Situatie'}/>,
        <MenuLink key={'appartementen'} url={'/appartementen'} title={'Appartementen'}/>,
        <MenuLink key={'beschikbaar'} url={'/beschikbaar'} title={'Beschikbaar'}/>,
        <MenuLink key={'extra'} url={'/extra'} title={'Extra'} important={false}/>,
        <MenuLink key={'financieel-advies'} url={'/financieel-advies'} title={'Financieel advies'} important={false}/>,
        <MenuLink key={'downloads'} url={'/downloads'} title={'Downloads'} important={false}/>,
        <MenuLink key={'contact'} url={'/contact'} title={'Contact'} important={false}/>,
    ]

    return (
        <>
            <CssBaseline/>
            <AppBar
                position="relative"
                elevation={0}
                color={'transparent'}
                component={'nav'}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'row',
                    paddingY: 2,
                }}
            >
                <Toolbar
                    disableGutters
                    component="nav"
                    variant="regular"
                    sx={{
                        maxWidth: theme => theme.breakpoints.values.xl,
                        width: '100%'
                    }}
                >
                    <Box
                        sx={{
                            display: {xs: 'flex', md: 'none'}
                        }}
                        flex={1}
                        paddingX={2}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                    >
                        <Link to={'/'}>
                            <StaticImage
                                src={'../../images/logo.png'}
                                width={200}
                                alt={'logo'}
                            />
                        </Link>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleDrawerToggle}
                            color="inherit"
                        >
                            <MenuIcon/>
                        </IconButton>
                    </Box>
                    <Box
                        sx={{display: {xs: 'none', md: 'flex'}}}
                        width={'100%'}
                        justifyContent={'space-around'}
                        alignItems={'center'}
                    >
                        <Link to={'/'}>
                            <StaticImage
                                src={'../../images/logo.png'}
                                width={200}
                                alt={'logo'}
                            />
                        </Link>
                        {menu}
                    </Box>
                </Toolbar>
            </AppBar>
            <nav>
                <Drawer
                    anchor={'right'}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: {xs: 'block', md: 'none'},
                        '& .MuiDrawer-paper': {boxSizing: 'border-box', width: 240},
                    }}
                >
                    <IconButton
                        onClick={handleDrawerToggle}
                        sx={{ alignSelf: 'flex-end' }}
                    >
                        <Close />
                    </IconButton>
                    {menu}
                </Drawer>
            </nav>
        </>
    )
}

export default Header
