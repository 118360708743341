import React, {ReactNode} from 'react';
import {Theme} from '@mui/material/styles';
import Box from "@mui/material/Box";
import Footer from "./footer"
import Navbar from "./navbar"

interface Props {
    children: ReactNode
    theme: Theme
}

export default function Layout({children}: Props) {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
            }}
        >
            <Navbar/>
            <Box
                component={'main'}
                flexGrow={1}
            >
                {children}
            </Box>
            <Box
                component={'footer'}
                sx={{
                    backgroundColor: 'secondary.main',
                    fontColor: 'common.white'
                }}
            >
                <Footer/>
            </Box>
        </Box>
    );
}
