import React from 'react';
import {Link, Grid, IconButton, Stack, Typography} from "@mui/material";
import {StaticImage} from "gatsby-plugin-image";
import Box from "@mui/material/Box";
import {Instagram, LinkedIn} from "@mui/icons-material";
import Container from "@mui/material/Container";

export default () => {
    return (
        <Box
            sx={{
                backgroundColor: 'common.white'
            }}
        >
            <Container maxWidth={'xl'}>
                <Grid
                    container
                    paddingY={4}
                    spacing={4}
                    textAlign={'center'}
                    alignItems={'center'}
                >
                    <Grid
                        item
                        xs={12}
                        md={7}
                        display={'flex'}
                        flexDirection={'column'}
                        alignItems={'center'}
                    >
                        <Stack
                            direction={{sx: 'column', sm: 'row'}}
                            alignItems={'center'}
                        >
                            <div>
                                <IconButton
                                    href={'https://www.instagram.com/dna.makelaars/'}
                                    target={"_blank"}
                                    color={'primary'}
                                >
                                    <Instagram color={'primary'}/>
                                </IconButton>
                                <IconButton
                                    disabled
                                    color={'inherit'}
                                >
                                    <LinkedIn color={'primary'}/>
                                </IconButton>
                            </div>
                            <Typography
                                textTransform={'uppercase'}
                                display={'block'}
                                marginLeft={2}
                                variant={'body2'}
                            >
                                © De Realisatie 2023 | <Link href="https://www.dna-makelaars.nl/privacyverklaring.pdf"
                                                             rel="noopener noreferrer" target="_blank">privacy
                                voorwaarden</Link>
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={5}
                    >
                        <Stack
                            direction={{sx: 'column', sm: 'row'}}
                            spacing={10}
                            alignItems={'center'}
                            justifyContent={'center'}
                        >
                            <StaticImage
                                src={'../../images/logos/de-realisatie@2x.png'}
                                alt={'logo de realisatie'}
                                layout={'fixed'}
                                height={53}
                                style={{
                                    marginBottom: 20
                                }}
                            />
                            <StaticImage
                                src={'../../images/logos/210311_logo_vanwensen_socials@2x.png'}
                                alt={'logo de realisatie'}
                                layout={'fixed'}
                                height={110}
                                style={{
                                    marginBottom: 20
                                }}
                            />
                        </Stack>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}