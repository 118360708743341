import React, {ReactNode} from 'react';

import theme from './theme';
import {ThemeProvider} from "@mui/material/styles";

interface Props {
    element: ReactNode
}

export default function wrapWithProvider({element}: Props) {
    return <ThemeProvider theme={theme}>{element}</ThemeProvider>
}
